import { paginateArray, sortCompare } from '@/libs/filter-data'
import { ref, computed } from '@vue/composition-api'
import store from '@/store'
import { useRouter } from '@core/utils/utils'

// Notification
// import { useToast } from 'vue-toastification/composition'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function usePedidos() {
  // Use route
  const { route, router } = useRouter()
  const routeId = computed(() => {
    const { id } = route.value.params
    return id
  })
  // Use toast
  // const toast = useToast()
  // ------------------------------------------------
  // UI
  // ------------------------------------------------
  const isAddNewProductoSidebarActive = ref(false)
  const productoSelect = ref({})
  const newInventoryProducts = ref([])
  const newInventoryTitulo = ref()
  const newInventoryNotas = ref()
  const newEntradaProducts = ref([])
  const newEntradaDocumento = ref()
  const newEntradaNotas = ref()
  const isEditProducto = ref(false)

  // ------------------------------------------------
  // DATOS: Productos - Clientes - Pedidos - Rutas - Vehículos
  // ------------------------------------------------
  const productos = computed(() => store.state.productos.productos)
  const inventarios = computed(() => store.state.productos.inventarios)
  const inventarioProductos = computed(() => {
    const { id } = route.value.params
    if (!inventarios.value.length || !id) {
      return []
    }
    const result = []
    const inventarioSelect = inventarios.value.filter(p => p.id === id)[0]
    if (inventarioSelect) {
      // result = inventarioSelect.productos || []
      const productosInventario = inventarioSelect.productos || []
      for (let index = 0; index < productosInventario.length; index += 1) {
        const producto = productosInventario[index]
        producto.name = productos.value?.filter(p => p.id === producto.id)[0]?.name || '** PRODUCTO NO ENCONTRADO'
        result.push(producto)
      }
    }
    return result
  })

  const inventarioSelected = computed(() => {
    const { id } = route.value.params
    if (!inventarios.value.length || !id) {
      return null
    }
    const result = inventarios.value.filter(p => p.id === id)[0]
    return result
  })

  const entradas = computed(() => store.state.productos.entradas)

  const entradaProductos = computed(() => {
    const { id } = route.value.params
    if (!entradas.value.length || !id) {
      return []
    }
    const result = []
    const entradaSelect = entradas.value.filter(p => p.id === id)[0]
    if (entradaSelect) {
      // result = inventarioSelect.productos || []
      const productosEntrada = entradaSelect.productos || []
      for (let index = 0; index < productosEntrada.length; index += 1) {
        const producto = productosEntrada[index]
        producto.name = productos.value?.filter(p => p.id === producto.id)[0]?.name || '** PRODUCTO NO ENCONTRADO'
        result.push(producto)
      }
    }
    return result
  })

  const entradaSelected = computed(() => {
    const { id } = route.value.params
    if (!entradas.value.length || !id) {
      return null
    }
    const result = entradas.value.filter(p => p.id === id)[0]
    return result
  })

  const fetchProductos = () => store.dispatch('productos/getProductos')
  const fetchInventarios = () => store.dispatch('productos/getInventarios')
  const fetchEntradas = () => store.dispatch('productos/getEntradas')
  const unsubscribeInventarios = () => store.dispatch('productos/UNSUSCRIBEIVENTARIOS')
  const unsubscribeEntradas = () => store.dispatch('productos/UNSUSCRIBEENTRADAS')

  const productosList = computed(() => {
    const result = []
    for (let index = 0; index < productos.value.length; index += 1) {
      const c = productos.value[index]
      result.push({
        id: c.id,
        title: `${c.id} ${c.name}`,
        name: c.name,
      })
    }
    return result
  })

  const stocksProductos = computed(() => {
    const result = []
    for (let index = 0; index < productos.value.length; index += 1) {
      const c = productos.value[index]
      result.push({
        id: c.id,
        title: `${c.id} ${c.name}`,
        name: c.name,
        unitInStock: c.unitInStock,
        picture: c.pictures[0]?.link,
      })
    }
    return result
  })

  // ------------------------------------------------
  // searchQuery
  // ------------------------------------------------
  const searchQuery = ref('')
  const updateSearchQuery = txt => {
    searchQuery.value = txt
  }

  // ------------------------------------------------
  // Table Options
  // ------------------------------------------------
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const perPage = ref(10)
  const currentPage = ref(1)
  const perPageOptions = ref([10, 25, 50, 100, 500])
  const tableColumns = [
    { key: 'id', label: 'ID', sortable: true },
    { key: 'name', label: 'Nombre', sortable: true },
    { key: 'size', label: 'Medidas (LWH)', sortable: true },
    { key: 'precio', label: 'Precio', sortable: true },
    { key: 'unitInStock', label: 'Stock', sortable: true },
    // { key: 'actions' },
  ]

  const inventSortBy = ref('id')
  const inventIsSortDirDesc = ref(true)
  const inventPerPage = ref(10)
  const inventCurrentPage = ref(1)
  const inventPerPageOptions = ref([10, 25, 50, 100, 500])
  const inventTableColumns = [
    { key: 'id', label: 'ID', sortable: true },
    { key: 'fecha', label: 'Fecha', sortable: true },
    { key: 'titulo', label: 'Titulo', sortable: true },
    // { key: 'actions' },
  ]

  const editProducto = p => {
    console.log(p)
    // productoSelect.value = p
    // isEditProducto.value = true
  }
  const addProducto = () => { isAddNewProductoSidebarActive.value = true }

  const editInventario = p => {
    console.log(p)
    // productoSelect.value = p
    // isEditProducto.value = true
  }
  const addInventario = () => { isAddNewProductoSidebarActive.value = true }

  // ------------------------------------------------
  // Productos Filters
  // ------------------------------------------------
  const itemsFilterProductos = computed(() => {
    const queryLowered = searchQuery.value.toLowerCase()
    let result = productos.value
    result = productos.value.filter(
      producto =>
        /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
        (producto.id.toLowerCase().includes(queryLowered) || producto.name.toLowerCase().includes(queryLowered) || producto.sku.toLowerCase().includes(queryLowered)),
    )
    const sortedData = result.sort(sortCompare(sortBy.value))
    if (isSortDirDesc.value) sortedData.reverse()
    return sortedData
  })

  const itemsFiltereds = computed(() => {
    const result = itemsFilterProductos.value
    if (!result.length) return []
    return paginateArray(result, perPage.value, currentPage.value)
  })

  // ------------------------------------------------
  // Inventarios Filters
  // ------------------------------------------------
  const itemsFilterInventarios = computed(() => {
    const queryLowered = searchQuery.value.toLowerCase()
    let result = inventarios.value
    result = inventarios.value.filter(
      inventario =>
        /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
        (inventario.id.toLowerCase().includes(queryLowered) || inventario.titulo.toLowerCase().includes(queryLowered) || new Date(parseInt(inventario.fecha, 10)).toLocaleDateString().toLowerCase().includes(queryLowered)),
    )
    const sortedData = result.sort(sortCompare(sortBy.value))
    if (isSortDirDesc.value) sortedData.reverse()
    return sortedData
  })

  const itemsFilteredsInventarios = computed(() => {
    const result = itemsFilterInventarios.value
    if (!result.length) return []
    return paginateArray(result, perPage.value, currentPage.value)
  })

  // ------------------------------------------------
  // Productos Inventario Filters
  // ------------------------------------------------
  const tableColumnsProductosInventario = [
    { key: 'id', label: 'ID', sortable: true },
    { key: 'name', label: 'Nombre', sortable: true },
    { key: 'uds', label: 'Stock', sortable: true },
    // { key: 'actions' },
  ]

  const exportToExcel = () => {
    console.log('Exportar listado')
  }

  const newInventory = () => {
    // console.log('Creando registros')
    // newInventoryProducts.value = []
    newInventoryProducts.value = JSON.parse(JSON.stringify(stocksProductos.value))
    newInventoryTitulo.value = ''
    newInventoryNotas.value = ''
  }

  const updatedStock = () => {
    // Productos que cambian:
    const result = []
    newInventoryProducts.value.forEach(item => {
      const { id, unitInStock } = item
      const stockNew = parseInt(item.unitInStock, 10)
      const stockOld = parseInt(stocksProductos.value.filter(i => i.id === id)[0].unitInStock, 10)
      if (stockNew !== stockOld) {
        result.push({
          id,
          uds: parseInt(unitInStock, 10),
        })
      }
    })
    // Comprueba que hay cambios
    if (!result.length) return
    // Guarda los resultados
    const payload = {
      fecha: `${new Date().getTime()}`,
      titulo: newInventoryTitulo.value.length ? newInventoryTitulo.value : 'Ordinary inventory',
      notas: newInventoryNotas.value.length ? newInventoryNotas.value : '-',
      productos: result,
    }
    // console.log(payload)
    store.dispatch('productos/setInventarios', payload)
      .then(() => {
        router.push({ name: 'bimbo-inventarios-list' })
      })
  }

  // ------------------------------------------------
  // Entradas Filters
  // ------------------------------------------------
  const itemsFilterEntradas = computed(() => {
    const queryLowered = searchQuery.value.toLowerCase()
    let result = entradas.value
    result = entradas.value.filter(
      entrada =>
        /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
        (entrada.id.toLowerCase().includes(queryLowered) || entrada.titulo.toLowerCase().includes(queryLowered) || new Date(parseInt(entrada.fecha, 10)).toLocaleDateString().toLowerCase().includes(queryLowered)),
    )
    const sortedData = result.sort(sortCompare(sortBy.value))
    if (isSortDirDesc.value) sortedData.reverse()
    return sortedData
  })

  const itemsFilteredsEntradas = computed(() => {
    const result = itemsFilterEntradas.value
    if (!result.length) return []
    return paginateArray(result, perPage.value, currentPage.value)
  })

  // ------------------------------------------------
  // Productos Entrada Filters
  // ------------------------------------------------
  const tableColumnsProductosEntrada = [
    { key: 'id', label: 'ID', sortable: true },
    { key: 'name', label: 'Nombre', sortable: true },
    { key: 'uds', label: 'Uds', sortable: true },
    // { key: 'actions' },
  ]

  const entradasTableColumns = [
    { key: 'id', label: 'ID', sortable: true },
    { key: 'fecha', label: 'Fecha', sortable: true },
    { key: 'documento', label: 'Documento', sortable: true },
    // { key: 'actions' },
  ]

  const newEntradas = () => {
    console.log('Creando registros')
    // newInventoryProducts.value = []
    newEntradaProducts.value = JSON.parse(JSON.stringify(stocksProductos.value))
    newEntradaDocumento.value = ''
    newEntradaNotas.value = ''
  }

  const saveEntry = () => {
    console.log('saveEntry')
    // Productos que entran:
    const result = newEntradaProducts.value.filter(i => i.uds).map(r => ({
      id: r.id,
      uds: parseInt(r.uds, 10),
    }))
    // Comprueba que hay cambios
    if (!result.length) return
    // Guarda los resultados
    // console.log(result)
    const payload = {
      fecha: `${new Date().getTime()}`,
      documento: newEntradaDocumento.value.length ? newEntradaDocumento.value : 'N/A',
      notas: newEntradaNotas.value.length ? newEntradaNotas.value : '-',
      productos: result,
    }
    // console.log(payload)
    store.dispatch('productos/setEntradas', payload)
      .then(() => {
        router.push({ name: 'bimbo-entradas-list' })
      })
  }

  return {
    // UI
    isAddNewProductoSidebarActive,
    productoSelect,
    isEditProducto,
    // Datos
    productos,
    productosList,
    itemsFilterProductos,
    itemsFiltereds,
    inventarios,
    itemsFilterInventarios,
    itemsFilteredsInventarios,
    itemsFilterEntradas,
    itemsFilteredsEntradas,
    inventarioProductos,
    routeId,
    inventarioSelected,
    stocksProductos,
    newInventoryProducts,
    newInventoryTitulo,
    newInventoryNotas,
    entradas,
    newEntradaProducts,
    entradaProductos,
    newEntradaDocumento,
    newEntradaNotas,

    // Filtros
    searchQuery,
    updateSearchQuery,

    // Tables
    perPage,
    currentPage,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    tableColumns,
    tableColumnsProductosInventario,
    tableColumnsProductosEntrada,

    inventPerPage,
    inventCurrentPage,
    inventPerPageOptions,
    inventSortBy,
    inventIsSortDirDesc,
    inventTableColumns,
    entradasTableColumns,
    entradaSelected,

    // methods
    fetchProductos,
    fetchEntradas,
    editProducto,
    addProducto,
    exportToExcel,
    fetchInventarios,
    unsubscribeInventarios,
    unsubscribeEntradas,
    editInventario,
    addInventario,
    newInventory,
    updatedStock,
    newEntradas,
    saveEntry,
  }
}
